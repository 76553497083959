var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__input"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center line-height-12 mb-20 mb-lg-40"
  }, [_vm._v(" 묻고 답하기 ")]), _c('p', {
    staticClass: "page-text page-text--sm mb-8 mb-lg-16 primary--text"
  }, [_vm._v(" *입력시 유의사항 안내 : 익명 보장을 위해 회원이시더라도 작성자는 저장되지 않으며, 작성시 입력한 비밀번호를 잊으시면 질의하신 내용 및 답변은 확인이 불가합니다. ")]), _vm._m(0), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "제목",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "제목을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "비밀번호",
      "pointer": ""
    }
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "v-cehckbox--sm mr-2",
    attrs: {
      "label": "비밀글",
      "hide-details": ""
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "placeholder": "비밀번호를 입력하세요.",
      "outlined": "",
      "hide-details": ""
    }
  })], 1)], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "질문 키워드",
      "pointer": ""
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "--선택--",
      "outlined": "",
      "hide-details": ""
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "질문 내용",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "질문 내용을 입력하세요.",
      "outlined": "",
      "hide-details": ""
    }
  })], 1)], 1), _c('div', {
    staticClass: "form__agreement"
  }, [_c('v-checkbox', {
    attrs: {
      "label": "질문 2차 사용 동의서",
      "hide-details": ""
    }
  }), _c('button', {
    staticClass: "form__agreement__btn",
    attrs: {
      "onclick": "window.open('/use', '', 'width=483,height=700');"
    }
  }, [_vm._v("전문보기")])], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 리스트 ")]), _c('v-btn', {
    staticClass: "min-w-120px min-w-lg-160px",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" 질의하기 ")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-8 mb-lg-20 text-right"
  }, [_c('p', {
    staticClass: "page-text page-text--sm grey-6--text line-height-1"
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("*")]), _vm._v(" 필수입력 항목입니다. ")])]);

}]

export { render, staticRenderFns }